export default {
  auxiliaryComment: {
    message: "Use `auxiliaryCommentBefore` or `auxiliaryCommentAfter`",
  },
  blacklist: {
    message: "Put the specific transforms you want in the `plugins` option",
  },
  breakConfig: {
    message: "This is not a necessary option in Babel 6",
  },
  experimental: {
    message: "Put the specific transforms you want in the `plugins` option",
  },
  externalHelpers: {
    message:
      "Use the `external-helpers` plugin instead. " +
      "Check out http://babeljs.io/docs/plugins/external-helpers/",
  },
  extra: {
    message: "",
  },
  jsxPragma: {
    message:
      "use the `pragma` option in the `react-jsx` plugin. " +
      "Check out http://babeljs.io/docs/plugins/transform-react-jsx/",
  },
  loose: {
    message:
      "Specify the `loose` option for the relevant plugin you are using " +
      "or use a preset that sets the option.",
  },
  metadataUsedHelpers: {
    message: "Not required anymore as this is enabled by default",
  },
  modules: {
    message:
      "Use the corresponding module transform plugin in the `plugins` option. " +
      "Check out http://babeljs.io/docs/plugins/#modules",
  },
  nonStandard: {
    message:
      "Use the `react-jsx` and `flow-strip-types` plugins to support JSX and Flow. " +
      "Also check out the react preset http://babeljs.io/docs/plugins/preset-react/",
  },
  optional: {
    message: "Put the specific transforms you want in the `plugins` option",
  },
  sourceMapName: {
    message:
      "The `sourceMapName` option has been removed because it makes more sense for the " +
      "tooling that calls Babel to assign `map.file` themselves.",
  },
  stage: {
    message:
      "Check out the corresponding stage-x presets http://babeljs.io/docs/plugins/#presets",
  },
  whitelist: {
    message: "Put the specific transforms you want in the `plugins` option",
  },

  resolveModuleSource: {
    version: 6,
    message: "Use `babel-plugin-module-resolver@3`'s 'resolvePath' options",
  },
  metadata: {
    version: 6,
    message:
      "Generated plugin metadata is always included in the output result",
  },
  sourceMapTarget: {
    version: 6,
    message:
      "The `sourceMapTarget` option has been removed because it makes more sense for the tooling " +
      "that calls Babel to assign `map.file` themselves.",
  },
} as { [name: string]: { version?: number; message: string } };
