export default {
  "Object.assign": {
    chrome: "49",
    opera: "36",
    edge: "13",
    firefox: "36",
    safari: "10",
    node: "6",
    deno: "1",
    ios: "10",
    samsung: "5",
    opera_mobile: "36",
    electron: "0.37",
  },
};
